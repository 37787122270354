




















































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
// import headerInfo from '../page/components/header.vue'
import headerInfo from './components/header.vue'
import { getExamScore, getContent, getExamDetail } from '@/modules/exam/api/index'
import { getExamChance } from '@/modules/course/api'
import { formatTime, formatCountTime } from '@/modules/exam/filters/utils'
import IndexMixin from '../mixins/indexMixin'


@Component({
  components: {
    headerInfo
  }
})
export default class ClassWrap extends Mixins(IndexMixin) {
  // @Prop() id: any
  isLoading: boolean = false
  errorObj: any = null
  isMarking: boolean = false // 是否正在批阅
  itemList: Array<any> = [
    { title: '答对题数', num: 0, total: 100, color: '#11D161', slug: 'correct_count' },
    { title: '答错题数', num: 0, total: 100, color: '#E94334', slug: 'wrong_count' },
    { title: '未答题数', num: 0, total: 100, color: '#F0F0F0', slug: 'skip_count' }
  ]
  timer: any
  result: any = {
    score: 0,
    title: '',
    start_time: '',
    end_time: '',
    skip_count: 0,
    correct_count: 0,
    wrong_count: 0,
    total_count: 0
  }
  examDetail: any = {
    name: '',
    latest_paper: {
      score: 0,
      start_time: 0,
      end_time: 0
    }
  }
  duration: any = ''
  remainingExamineTimes: any = ''

  get examMode() {
    let mode = this.examDetail.exam_mode
    if (mode === 'generic') {
      return 'exam'
    } else if (mode === 'random') {
      return 'random'
    } else {
      return 'practice'
    }
  }

  created() {
    this.initPage()
    this.timer = window.setInterval(() => {  
      this.getExamResult()
    }, 5000)
  }

  formatTime(time: any) {
    return formatTime(time)
  }

  goBack() {
    let name = ''
    switch (this.contentType) {
      case 'provingground': // 试炼场
        name = 'Briprovingground'
        break
      case 'trainingcampclass': // 训练营
        name = 'Britrainingcampclass'
        break
      case 'course': // 课程
        name = 'Bricourse'
        break
    }
    this.$router.replace({name, params: { id: this.contentId }})
  }

  initPage() {
    this.getExamChance()
    this.isLoading = true
    Promise.all([this.getExamDetail(), this.getExamResult()]).then(() => {
      this.isLoading = false
    }).catch(e => {
      this.isLoading = false
      if (e.response.data.error === '40202') { // 未订阅且不是试学
        this.goBack()
      } else if (e.errorObj) {
        this.errorObj = e.errorObj
      }
    })
  }

  // 获取测验详情
  getExamDetail() {
    return getExamDetail(this.id).then(res => {
      console.log(res, '获取测验详情');
      
      this.examDetail = res
      this.duration = formatCountTime((this.examDetail.latest_paper.end_time - this.examDetail.latest_paper.start_time) * 1000)
      return Promise.resolve()
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getExamChance() {
    getExamChance({id: this.id}).then(res => {
      console.log(res, 'examchange');
      this.remainingExamineTimes = res.remaining_examine_times;
    })
  }

  // 获取测验结果
  getExamResult() {
    getExamScore(this.id).then((res: any) => {
      console.log(res, '获取测验结果');
      
      this.isMarking=false
      this.result = res
      this.itemList.forEach(x => {
        x.total = this.result.total_count
        x.num = this.result[x.slug]
      })
      console.log(this.itemList, 'this.itemList');
      
      this.getContent(this.result.score)
      return Promise.resolve()
    }).catch((e: any) => {
      if (e && e.response && e.response.data && e.response.data.error === '43003') {
        this.isMarking = true
        return Promise.resolve()
      }
      let {errorObj} = e
      // if (errorObj) this.$toast(errorObj.toastMsg)
      return Promise.reject(e)
    })
  }

  // 获取推荐内容
  getContent(score: any) {
    if (this.contentType !== 'provingground') return
    getContent(this.id, {score: score}).then((res) => {
      console.log(res, '获取推荐内容');
      

      // this.recommendDetail = isEmpty(res) ? null : res
    })
  }

  // 重新考试
  toAgain() {
    this.$router.push({
      name: 'exam',
      query: {
        renderType: 'exam',
        contentId: this.contentId,
        contentType: this.contentType,
        examType: this.examMode
      }
    })
  }

  toAnalysis() {
    if (this.isMarking) {
      // this.$refs.confirmDialog.open()
    } else {
      this.$router.push({ name: 'examAnalysis' })
    }
  }

  beforeDestroy(){
    clearInterval(this.timer); // 清除定时器
    this.timer = null;
  }
}
